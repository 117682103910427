.home {
    &__content {
        flex-grow: 1;
        padding: 10vh 0;

        @media (min-width: $md-mobile) {
            padding: 12vh 0;
        }
        @media (min-width: $lg-mobile) {
            padding: 14vh 0;
        }
        @media (min-width: $sm-pc) {
            padding: 16vh 0;
        }
    }

    &__title {
        color: white;
        font-size: 40px;
        line-height: 110%;
        
        @media (min-width: $md-mobile) {
            font-size: 50px;
        }
        @media (min-width: $lg-mobile) {
            font-size: 64px;
        }
        @media (min-width: $sm-pc) {
            font-size: 80px;
        }

        &:nth-of-type(2) {
            margin-bottom: 50px;
        }
    }

    &__subtitle {
        font-size: 22px;
        line-height: 110%;
        
        @media (min-width: $md-mobile) {
            font-size: 24px;
        }
        @media (min-width: $lg-mobile) {
            font-size: 28px;
        }
        @media (min-width: $sm-pc) {
            font-size: 30px;
        }

        &:nth-of-type(2) {
            margin-bottom: 50px;
        }
    }

    &__btn {
        padding: 18px 26px;
        border-radius: 10px;
        margin-bottom: 50px;
        font-size: 20px;

        @media (min-width: $md-mobile) {
            margin-bottom: 80px;
        }
        @media (min-width: $lg-mobile) {
            margin-bottom: 110px;
        }
        @media (min-width: $sm-pc) {
            margin-bottom: 150px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        padding: 50px 0;
        opacity: 0;

        @media (min-width: $lg-mobile) {
            flex-direction: row;
        }

        &-icon {
            width: 120px;
            flex-shrink: 0;
        }

        &-text {
            font-size: 20px;
            line-height: 110%;
            text-align: center;

            @media (min-width: $md-mobile) {
                font-size: 22px;
            }
            @media (min-width: $lg-mobile) {
                font-size: 24px;
                text-align: left;
            }
            @media (min-width: $sm-pc) {
                font-size: 30px;
            }
        }
    }
}