$font-color: #eeeeee;
$font-color2: #dddddd;
$shadow-color: #101010;
$blue: #0c70de;
$purple: #390d8a;

$background: #111111;
$background2: rgb(17, 0, 39);

$font-family: 'Catamaran', sans-serif;

$sm-mobile: 400px;
$md-mobile: 520px;
$lg-mobile: 780px;
$sm-pc: 1024px;
$md-pc: 1366px;
$lg-pc: 1500px;