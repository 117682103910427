html {
    font-family: $font-family;
    color: $font-color2;
    scrollbar-color: $blue #101010;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: $blue;
}

::-webkit-scrollbar-track {
    background-color: #101010;
}

body {
    background: linear-gradient(90deg, $background, $background2, $background);
}

.app {
    background-image: url('../assets/nothing.png');
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    transition: background-image 0.5s;
}

.main {
    width: calc(100% - 40px);
    max-width: 1500px;
    padding-top: 70px;
    margin: 0 auto;

    &__content {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 70px);
        padding: 20px 0;
    }

    &__title {
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 10px;
        background-color: $purple;
    }

    &__subtitle {
        color: $font-color;
    }

    &__section {
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 10px;
        background-color: #181818;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
}

.link {
    display: block;
    color: $font-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    aspect-ratio: 1/1;

    &--natural {
        aspect-ratio: inherit;
    }

    &--radius {
        border-radius: 10px;
    }

    &--icon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 60px;
        border-radius: 20px;
        background-color: rgba(0,0,0,0.7);
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.7);
    }
}